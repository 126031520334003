.splash-image {
    animation: zoomInOut 20s ease-in-out infinite;
    object-fit: cover;
}

@keyframes zoomInOut {

    0%,
    100% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }
}